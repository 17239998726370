import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import LocomotiveScroll from "locomotive-scroll";

const application = Application.start();
const context = require.context("../js/controllers/front", true, /_controller\.js$/);
application.load(definitionsFromContext(context));

// JS
import "../js/common.js";
import "../js/youtube_api_loader.js";

// Styles
import "../styles/front/locomotive_scroll.css";
import "../styles/front/shared/contact_form.scss";
import "../styles/front/shared/cookies_modal.scss";
import "../styles/front/shared/fade_block.scss";
import "../styles/front/shared/filters_menu.scss";
import "../styles/front/shared/footer.scss";
import "../styles/front/shared/gallery.scss";
import "../styles/front/shared/image_viewer.scss";
import "../styles/front/shared/langs_select.scss";
import "../styles/front/shared/menu.scss";
import "../styles/front/shared/modal.scss";
import "../styles/front/shared/video_viewer.scss";
import "../styles/front/shared/founder_card.scss";
import "../styles/front/shared/show_card.scss";
import "../styles/front/shared/artes_visuales_banner.scss";
import "../styles/front/shared/technical_list.scss";
import "../styles/front/shared/shows_list.scss";
import "../styles/front/shared/founders_section.scss";
import "../styles/front/front.scss";
import "../styles/front/home.scss";
import "../styles/front/shows.scss";
import "../styles/front/founders.scss";
import "../styles/front/shows_founders.scss";
import "../styles/front/history.scss";
import "../styles/front/contact.scss";
import "../styles/front/qrs.scss";

let locomotiveScroll;
let prevWindowWidth = window.innerWidth;

window.reloadLocomotiveScroll = function() {
  locomotiveScroll.update();
}

function loadLocomotiveScroll() {
  locomotiveScroll = new LocomotiveScroll({
    el: document.querySelector('[data-scroll-container]'),
    smooth: true,
    getDirection: true,
    reloadOnContextChange: true,
    resetNativeScroll: false,
    firefoxMultiplier: 100,
    tablet: {
      smooth: false,
      getDirection: true,
    },
    smartphone: {
      smooth: false,
      getDirection: true,
    }
  });

  locomotiveScroll.on("call", (func, dir, obj) => {
    if (func == "setVisible") {
      obj.el.classList.add("visible");
    }
  });

  locomotiveScroll.on("scroll", (obj) => {
    let inners = document.querySelectorAll(".menu--nav, .modal, .video-viewer--viewer.-as-modal");

    for (let inner of inners) {
      inner.onmouseover = function() {
        locomotiveScroll.stop();
      };

      inner.onmouseout = function() {
        locomotiveScroll.start();
      };
    }
  });

  window.addEventListener("resize", debounce(function() {
    if (window.innerWidth > 1025 && prevWindowWidth - window.innerWidth > 200 ||  window.innerWidth - prevWindowWidth > 200) {
      prevWindowWidth = window.innerWidth;
      location.reload();
    }
  }), 100);

  const technicalLists = document.querySelectorAll(".technical-list");
  for (let technicalList of technicalLists) {
    technicalList.classList.remove("open");
  }

  locomotiveScroll.update();
}

document.addEventListener("turbo:load", function () {
  // console.log(`Ctrl 1 -> ${new Date().getMilliseconds()} ms`);

  // new imagesLoaded(document.body).on("progress", function(instance, image) {
  //   var result = image.isLoaded ? "loaded" : "broken";
  //   console.log(`image is ${result} for ${image.img.src} -> ${new Date().getMilliseconds()} ms`);
  // });

  // console.log(`Ctrl 2 -> ${new Date().getMilliseconds()} ms`);

  new imagesLoaded(document.body, { background: true }, function() {
    // console.log(`Ctrl 3 -> ${new Date().getMilliseconds()} ms`);
    window.setTimeout(function() {
      document.body.classList.remove("loading");
    }, 100);

    window.setTimeout(function() {
      // console.log(`Ctrl 4 -> ${new Date().getMilliseconds()} ms`);

      loadLocomotiveScroll();

      // console.log(`Ctrl 5 -> ${new Date().getMilliseconds()} ms`);
    }, 80);

    // console.log(`Ctrl 6 -> ${new Date().getMilliseconds()} ms`);
  });
});
