import { Controller } from "stimulus";

let bodySize;

export default class extends Controller {
  static targets = ["main", "body"];

  connect() {
    // console.log("connect:accordion-list-controller");

    this.bodySize = this.bodyTarget.innerHeight;
    this.bodyTarget.style.height = `${this.bodySize}px`;
  }

  toggle() {
    if (this.mainTarget.classList.contains("open")) {
      this.close();
    }
    else {
      this.open();
    }

    // reloadLocomotiveScroll();
  }

  open() {
    this.mainTarget.classList.add("open");
  }

  close() {
    this.mainTarget.classList.remove("open");
  }
}
