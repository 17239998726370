import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["menu"];

  connect() {
    // console.log("connect::menu-controller");
  }

  toggle() {
    this.menuTarget.classList.toggle("open");
  }

  open() {
    this.menuTarget.classList.add("open");
  }

  close() {
    this.menuTarget.classList.remove("open");
  }
}
