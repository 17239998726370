import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    // console.log("connect::mega-menu-controller");

    if (window.innerWidth <= 1024 ) {
      // this.element.classList.add("lock");
      this.element.classList.add("visible");
    }
  }
}
