import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    // console.log("connect::langs-select-controller");
  }

  select() {
    window.location.href = this.element.selectedOptions[0].getAttribute("data-link");
  }
}
