import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form"];

  connect() {
    // console.log("connect::modal-controller");
  }

  open(event) {
    this.modalTarget = document.getElementById(event.target.getAttribute("data-modal"));

    if (this.modalTarget) {
      this.modalTarget.classList.add("show");
    }
  }

  close() {
    this.modalTarget.classList.remove("show");
  }

  clearInputs() {
    this.formTarget.reset();

    let inputs = this.formTarget.querySelectorAll("input");

    for (let input of inputs) {
      if (input.type == "submit") {
        continue;
      }

      if (input.type == "checkbox") {
        input.checked = false;
        continue;
      }

      input.value = "";
    }

    let selects = this.formTarget.querySelectorAll("select");

    for (let select of selects) {
      select.selectedIndex = 0;
    }
  }
}
