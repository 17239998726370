import { Controller } from "stimulus";
import "../../lazyload.min.js";

export default class extends Controller {
  connect() {
    // console.log("connect::lazy-load-controller");

    let images = document.querySelectorAll(this.element.getAttribute("lazy-load-target"));
    new lazyload(images);
  }
}
